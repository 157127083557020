<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon> {{ user_name }}
      </v-btn>
    </template>

    <v-list :tile="false" flat nav>
      <v-list-item
        link
        :to="{
          name: 'Perfil de usuario',
        }"
        >Perfil de usuario</v-list-item
      >
      <v-list-item link @click="cerrarSesion()">Cerrar Sesión</v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DefaultAccount',

  data: () => ({
    profile: [
      // { title: 'Profile' },
      // { title: 'Settings' },
      // { divider: true },
      { title: 'Cerrar sesión' },
    ],
  }),
  methods: {
    async cerrarSesion() {
      console.log('cerrarSesion');
      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
  },
  mounted() {
    console.log(this.$store.state.auth.payload.gpsusuario.usuarioAlias);
  },
  computed: {
    user_name() {
      return this.$store.state.auth.payload.gpsusuario.usuarioAlias;
    },
  },
};
</script>
